body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  background-color: rgb(240, 241, 247);
}

.row {
  --bs-gutter-x: 0 !important;
}

.single-main-section-dashboard{
  width: 100%;
}
.main-section-dashboard{
  width: 69%;
}
.sidebar-section-dashboard{
  width: 30%;
}
.p-relative{
  position: relative;
}

.bg-light{
  background-color: white !important;
}

.line-height-initial{
  line-height: initial;
}

.animated-loading{
  width: 100%;
  height: 250px;
  margin: 6px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  background-image: linear-gradient(
    90deg,
    #e4e4e4 0%,
    #f1f1f1 40%,
    #ededed 60%,
    #e4e4e4 100%
  );
  background-position: 0px 0px;
  background-repeat: repeat;
  animation: animatedBackground 5s linear infinite;
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1000px 0;
  }
}
.inner-animated-loading{
  position: relative;
}
.inner-animated-loading:after{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0.5;
  background-image: linear-gradient(
    90deg,
    #e4e4e4 0%,
    #f1f1f1 40%,
    #ededed 60%,
    #e4e4e4 100%
  );
  background-position: 0px 0px;
  background-repeat: repeat;
  animation: animatedBackground 5s linear infinite;
  border-radius: 5px;
}

.ql-editor{
  min-height: 200px;
  width: 100%;
}

.dropdown-toggle::after{
  display: none;
}

.dropdown .btn:hover{
  color: initial;
  background-color: transparent;
  border-color: transparent;
}

.post_tabs.nav-tabs .nav-link{
  color: #00a0df !important;
}
.post_tabs.nav-tabs .nav-link:hover{
  color: #222 !important;
}

.post_tabs.nav-tabs .nav-link.active{
  color: #222 !important;
  background-color: rgb(240, 241, 247) !important;
  border-color: #ccc;
  border-bottom-color: rgb(240, 241, 247) !important;
}

/* .post-wrapper .tab-content{
  border: 1px solid #ccc !important;
  border-top: none !important;
  padding: 5px;
} */

.post-wrapper .post_tabs{
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 10px;
  justify-content: center;
}

.challenge-tab-wrapper .post_tabs{
  background-color: #019fdf;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 0px;
  justify-content: center;
  padding-top: 5px;
  /* border-color: #019fdf; */
  border: none !important;
  border-radius: 0 !important;
}

.challenge-tab-wrapper .post_tabs.nav-tabs .nav-link.active{
  color: #222 !important;
  background-color: #fff !important;
  border-color: #fff;
  border-bottom-color: #fff !important;
}

.challenge-tab-wrapper .post_tabs.nav-tabs .nav-link{
  color: #fff !important;
}
.challenge-tab-wrapper .post_tabs.nav-tabs .nav-link:hover{
  color: #222 !important;
  background-color: #fff !important;
  border-color: #fff;
  border-bottom-color: #fff !important;
}

/* Deepak CSS */
.add_user_admin_bt {
  background: #52c249;
  color: #fff;
  border: none; 
  padding: 2px 17px 10px 13px;
  border-radius: 10px;
  float: right;
  margin-bottom: 10px;
  margin-left: 10px;
}

.pagination{
  justify-content: center;
}
.per-page-drop-down{
  padding:0px 9px 0px 0px;
}
.editIcon{
  margin-right: 6px;
}

.custom-modal-header{
    border-bottom: 1px solid #fff;
    background: #03a9f4;
    color: #fff;
    margin-bottom: 10px;
}
.saveBtn{
  background-color: #03a9f4;
  margin-right: 11px;
}
.btnRow{
  text-align: center;
  margin-top: 45px;
}

.main_inner_section{
  padding: 5px 0px;
  width: 100%;
  background-color: white;
  border-radius: 5px;
}
.heading-title{
  margin-bottom: 20px;
}
.searchFields{
  /* margin-left: -11px; */
}
.organization-modifications{
  margin-bottom: 25px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(67, 66, 66, 0.8);  
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}
.user-search{
  border: 1px solid;
}
.organization-drop-down{ 
  min-width: 247px;
  width: auto;
}
.check-mark{ 
  margin-left: 10px;
  margin-top: 5px;
}
.edit-org{
    margin-left: 10px;
    margin-top: -5px;
}
 .required-mark{
  color: red;
 }

 
#sidebar {
  min-width: 100%;
  max-width: 100%;
  background: #fff;
  color: #fff;
  transition: all 0.3s;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.06);
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.sidebar-menu-holder{
  width: 100%;
  flex: 1;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

.sidebar-menu-holder .accordion-item{
  border: none !important;
  box-shadow: none !important;
}
.sidebar-menu-holder .accordion-item .accordion-button:focus{
  box-shadow: none !important;
}

#sidebar.active {
  margin-left: -280px;
}

#sidebar .sidebar-header {
  padding: 20px;
  text-align: center;
}

#sidebar ul.components {
  padding: 20px 0;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a, #sidebar ul li button {
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  display: block;
  text-decoration: none;
  color: #717171;
  padding-left: 30px;
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  text-align: left;
}

#sidebar ul li a span.menu_icon, #sidebar ul li button span.menu_icon {
  margin-right: 11px;
  font-size: 17px;
}

#sidebar ul li a.active svg path {
  fill: white !important;
}

#sidebar ul li a:hover .iconHvr {
  fill: #fff !important;
}

#sidebar ul li a:hover, #sidebar ul li button:hover {
  background: #009fdf;
  color: #fff;
}

#sidebar ul.list-unstyled li a.logout {
  margin-top: 140px;
}

#sidebar ul li a.active {
  background: #009fdf;
  color: #fff;
}

.sidebar-logo{
  width: 60px; 
  height: 43px; 
  top:31px;
  left: 110px;
}
.right-section-form{
  margin-left: 10px;
}

.active>.page-link, .page-link.active{
  z-index: 0 !important;
}

.menu-name{
  margin-left: 15px;
  color: #717171;
  font-weight: 400;
}

.sub-menus{
  margin-left: 10px;
}

.sidebar .accordion-body {
  padding: 0px 0px 0px 0px;
}
/* Deepak CSS ends here */

.dropstart .dropdown-toggle::before{
  display: none;
}
 

.dashboard_main_input_card{
  background-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.challenges-accordian .accordion-item:first-of-type .accordion-button, .challenges-accordian .accordion-item:first-of-type{
  border-radius: 0 !important;
}
.challenges-accordian-main.challenges-accordian .accordion-item:first-of-type .accordion-button:not(.collapsed){
  border-top: none !important;
}

.challenges-accordian .accordion-button:focus{
  box-shadow: none !important;
}

.challenges-accordian .accordion-item:nth-child(odd) .accordion-button{
  background-color: #00a0df1c !important;
}

.challenges-accordian .accordion-button:not(.collapsed){
  color: #222 !important;
  background-color: #fff !important;
  box-shadow: none !important;
  border: 2px solid #4fa4dd !important;
  border-bottom: 2px solid #dee2e6 !important;
}

.challenges-accordian .accordion-collapse.show{
  border: 2px solid #4fa4dd !important;
  border-top: none !important;
}

.challenges-accordian .primary-badge, .assignment-modal .primary-badge{
  background-color: #4fa4dd !important;
}

.challenges-accordian .warning-badge, .assignment-modal .warning-badge{
  background-color: #e09603 !important;
}

.challenges-accordian .success-badge, .assignment-modal .success-badge{
  background-color: rgb(16, 200, 16) !important;
}
.challenges-accordian .light-badge{
  background-color: white !important;
  color: #222;
}

.text-right{
  text-align: right;
}

.fs-7{
  font-size: 0.7rem;
}

.add-challenge-modal-btn{
  background-color: rgb(16, 200, 16) !important;
  border-color: rgb(16, 200, 16) !important;
  font-weight: 700;
}

.sidebar-logout-btn{
  background-color: red !important;
  border-color: red !important;
  font-weight: 700;
  width: 100%;
  margin-bottom: 10px;
}

.create-challenge-btn{
  background-color: #4fa4dd !important;
  border-color: #4fa4dd !important;
  width: 100%;
}

.react-datepicker-wrapper{
  width: 100%;
}

.react-datepicker-popper{
  min-width: 330px !important;
}

.btn-light-custom{
  color: #009fdf;
  border: 1px solid #ccc;
}
.btn-light-custom:hover{
  color: #009fdf;
}

.btn-primary-custom{
  background-color: #009fdf;
  border-color: #009fdf;
}
.btn-primary-custom:hover{
  background-color: #009fdf;
  border-color: #009fdf;
}

.btn-link-exit-impersonate{
  color: #ff0000;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
}

.main-link-btns{
  text-decoration: none;
  color: #717171;
  transition: all 0.3s ease-in-out;
}
.main-link-btns:hover{
  color: #009fdf;
}

.elipsis-1{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table{
  vertical-align: middle;
}

.user-point-summary-date-picker .react-datepicker-wrapper .react-datepicker__input-container input{
  color: #009fdf;
  border: 1px solid #ccc;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  background-color: #f8f9fa;
  max-width: 185px;
  min-width: 185px;
}
.user-point-summary-date-picker.active .react-datepicker-wrapper .react-datepicker__input-container input{
  border: 1px solid #009fdf;
}
.user-point-summary-date-picker .react-datepicker-wrapper .react-datepicker__input-container input::placeholder {
  color: #009fdf;
  opacity: 1; /* Firefox */
}

.notification_modal .modal-dialog{
  margin-right: 14rem !important;
  margin-top: 3rem;
}

.notification_modal_backdrop.show{
  opacity: 0.2
}

.submenu-sidebar .nav li a{
  padding-left: 45px !important;
}

@media only screen and (max-width:1200px) {

  .main-section-dashboard{
    width: 100%;
  }
  .sidebar-section-dashboard{
    width: 100%;
    margin-top: 15px;
  }
  .sidebar-mobile-container{
    background-color: rgba(0, 0, 0, 0.06) !important;
  }

  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
    --bs-gutter-x: 0;
  }

  .modal-dialog-centered{
    width: 90%;
    max-width: 90%;
  }
}

@media only screen and (max-width:600px) {
  #sidebar {
    min-width: 80%;
    max-width: 80%;
  }
  .header-breadcrumb{
    display: none;
  }
  .profile-menu-icon-col, .sidebar-menu-icon-col{
    width: auto !important;
  }
}